"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetThemeMode = exports.useSupportDarkMode = exports.useIsDarkMode = void 0;
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const root_1 = require("src/utils/root");
const actions_1 = require("src/redux/actions");
const frame_1 = require("src/utils/frame");
const selectors_1 = require("src/redux/sagas/selectors");
function useThemeMode() {
    return (0, react_redux_1.useSelector)((state) => {
        const { themeMode } = state;
        return themeMode !== null && themeMode !== void 0 ? themeMode : 'system';
    });
}
function getDefaultThemeModeFromQuery() {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('theme_mode');
}
function useIsDarkMode() {
    return (0, react_redux_1.useSelector)((state) => {
        const { themeMode = 'system' } = state;
        if (themeMode === 'system') {
            const mq = window.matchMedia('(prefers-color-scheme: dark)');
            return mq.matches;
        }
        return themeMode === 'dark';
    });
}
exports.useIsDarkMode = useIsDarkMode;
function useSupportDarkMode() {
    return (0, react_redux_1.useSelector)((state) => {
        const config = (0, selectors_1.getConfig)(state);
        const { supportDarkMode } = state.survey;
        return config.forceSupportDarkMode ? true : supportDarkMode !== null && supportDarkMode !== void 0 ? supportDarkMode : false;
    });
}
exports.useSupportDarkMode = useSupportDarkMode;
function useSetThemeMode() {
    const themeMode = useThemeMode();
    const supportDarkMode = useSupportDarkMode();
    const root = (0, root_1.useRootService)().get();
    const dispatch = (0, react_redux_1.useDispatch)();
    const defaultThemeMode = getDefaultThemeModeFromQuery();
    const isDemo = new URLSearchParams(window.location.search).get('is_demo') === '1';
    (0, react_1.useEffect)(() => {
        if (isDemo) {
            root === null || root === void 0 ? void 0 : root.setAttribute('data-theme', 'light');
        }
    }, [isDemo, root]);
    (0, react_1.useEffect)(() => {
        const messageHandler = (event) => {
            const feedbackData = event.data;
            if (feedbackData.event === frame_1.EVENTS.SET_THEME_MODE) {
                dispatch((0, actions_1.setThemeMode)(feedbackData.mode));
            }
        };
        window.addEventListener('message', messageHandler);
        return () => {
            window.removeEventListener('message', messageHandler);
        };
    }, [dispatch]);
    (0, react_1.useEffect)(() => {
        if (supportDarkMode) {
            const mqChangeListener = (evt) => {
                if (themeMode === 'system') {
                    const prefersColorScheme = evt.matches ? 'dark' : 'light';
                    root.setAttribute('data-theme', prefersColorScheme);
                }
            };
            if (themeMode === 'system') {
                const mq = window.matchMedia('(prefers-color-scheme: dark)');
                const prefersColorScheme = mq.matches ? 'dark' : 'light';
                root.setAttribute('data-theme', defaultThemeMode !== null && defaultThemeMode !== void 0 ? defaultThemeMode : prefersColorScheme);
                mq.addEventListener('change', mqChangeListener);
                return () => {
                    mq.removeEventListener('change', mqChangeListener);
                };
            }
            root.setAttribute('data-theme', themeMode);
        }
        else {
            root.setAttribute('data-theme', 'light');
        }
    }, [supportDarkMode, themeMode, root]);
}
exports.useSetThemeMode = useSetThemeMode;
