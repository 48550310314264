"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .emotion__options {
        gap: var(--emotion-mobile-gap);
    }

    .app_surveyPreset_yandexActiveFeedback .emotion__options {
        width: 276px;
    }

    .emotion__label {
        display: var(--emotion-label-display-narrow);
    }
`;
